import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { BehaviorSubject, of } from 'rxjs';
import { UserService } from '../user/user.service';
import { UserService as User } from '../user.service';
import { ModerateRole } from '@shared/constant';
import { Location as LocationAngular } from '@angular/common';
import { AppRoutes } from 'src/app/config/routes.config';
import { CountryService } from '@shared/services/country.service';
import { Action } from '@shared/enum/action';
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public userRoles = new BehaviorSubject<any>(this.userService.getUserInfoFromLocalStorage());
  static actionPermission = Action.ALL;

  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private userService: UserService,
    private user: User,
    private location: LocationAngular,
    private countryService: CountryService
  ) {}

  checkPermission(requiredRoles: string[], roles: string[]) {
    const tokenId = this.tokenStorageService.getToken(); // || get token from cookie
    const isTokenValid = this.authService.isTokenExpired();
    // Check if the token is expired or invalid
    if (tokenId && !isTokenValid) {
      const hasPermission = requiredRoles.some((permission) => roles.includes(permission));
      return hasPermission;
    }
    return false;
  }
  async checkPermission1(token?: string): Promise<boolean> {
    const locationUrl = this.location.path().split('/')[1];
    return new Promise<boolean>(async (resolve) => {
      let hasPermission = false;
      let tokenId: string | null = '';
      if (token) {
        tokenId = token;
      } else {
        tokenId = this.tokenStorageService.getToken();
      }

      if (tokenId) {
        try {
          const res = await this.user.getModeratorRole().toPromise();
          // if (res && res.length > 0) {
          //   let countrysAssigned: string[] = [];
          //   let isSuperAdmin = false;
          //   res.map((item) => {
          //     if (
          //       item.role === ModerateRole.SUPER_ADMIN.code ||
          //       (item.type === 'MODULE_ADMIN' && item.type_moderator === 'BANNER_MODULE_ADMIN')
          //     ) {
          //       if (item.role === ModerateRole.SUPER_ADMIN.code) {
          //         isSuperAdmin = true;
          //       }
          //       hasPermission = true;
          //       PermissionService.actionPermission = Action.ALL;
          //       if (item.country_code && !isSuperAdmin) {
          //         countrysAssigned.push(item.country_code);
          //       }
          //     }
          //   });
          //   this.countryService.setlistCountryAssigned(countrysAssigned);
          //   // hasPermission = res.some(
          //   //   (item) =>
          //   //     item.role === ModerateRole.SUPER_ADMIN.code ||
          //   //     (item.type === 'MODULE_ADMIN' && item.type_moderator === 'BANNER_MODULE_ADMIN')
          //   // );
          // }
        } catch (error) {
          console.error(error);
        }
      }
      if (locationUrl && locationUrl !== AppRoutes.ADMIN) {
        hasPermission = true;
      }
      return resolve(true);
    });
  }
}
