import { Component, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '@layout/footer/footer.component';
import { HeaderComponent } from '@layout/header/header.component';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '@env/environment';
import { SidebarComponent } from '@layout/sidebar/sidebar.component';
import { TreeNodeComponent } from '@shared/components/tree-node/tree-node.component';
import { DropdownModule } from 'primeng/dropdown';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CountryService } from '@shared/services/country.service';
import { TranslateModule } from '@ngx-translate/core';
import { ModuleControllerService, SubModuleItem } from '@soctrip/angular-banner-service';
import { BannerFormService } from '@shared/services/banner-form.service';
import { AppRoutes } from 'src/app/config/routes.config';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    FormsModule,
    TranslateModule,
    /* primeng */
    DropdownModule,
    /* components */
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    TreeNodeComponent,
  ],
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  providers: [ModuleControllerService],
  encapsulation: ViewEncapsulation.None,
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  private http = inject(HttpClient);
  private router = inject(Router);
  private countryService = inject(CountryService);
  private unsubscribe$ = new Subject<void>();

  countries: any[];
  treeData: any[] = [];
  selectedCountry: any;
  treeModule = this.route.snapshot.paramMap.get('nameModule') ?? undefined;
  adminModule = AppRoutes.ADMIN;
  listCountryAssigned: String[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private moduleService: ModuleControllerService,
    private bannerFormService: BannerFormService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.countryService
      .getlistCountryAssigned()
      .pipe(takeUntil(this.destroy$))
      .subscribe((countries) => {
        this.listCountryAssigned = countries;
      });
    this.fetchCountryList();
    this.fetchTreeList();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  fetchCountryList() {
    const params = new HttpParams().set('validCountry', true);
    this.http
      .get(`${environment.BASE_API_URL}/regional/regional/country`, {
        params,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            if (this.listCountryAssigned.length > 0) {
              this.countries = res.data.filter((item) =>
                this.listCountryAssigned.includes(item.country_code)
              );
            } else {
              this.countries = res.data;
            }

            this.selectedCountry = this.countries[0];
            this.countryService.setCountry(this.countries[0].country_code);
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  fetchTreeList() {
    this.moduleService
      .getAll()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          if (res.success) {
            if (this.treeModule && this.treeModule !== this.adminModule) {
              this.treeData = res.data?.filter(
                (node) =>
                  node.name?.toUpperCase() === this.treeModule?.replaceAll('-', '_').toUpperCase()
              ) as any[];
            } else {
              this.treeData = res.data as any[];
            }
            this.bannerFormService.setTreeData(res.data as any[]);

            if (this.treeData.length > 0)
              this.router.navigate([
                `${this.treeModule}/${this.treeData[0]?.code?.replaceAll('_', '-').toLowerCase()}`,
              ]);
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  handleChangeCountry(event: DropdownChangeEvent) {
    this.selectedCountry = event.value;
    this.countryService.setCountry(event.value.country_code);
  }

  nodeSelectEventHandle(node: SubModuleItem) {
    if (this.treeModule) {
      this.router.navigate([
        `/${this.treeModule}/${node?.code?.replaceAll('_', '-').toLowerCase()}`,
      ]);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

interface DropdownChangeEvent {
  originalEvent: Event;
  value: any;
}
